type PageLayoutProps = {
  heading: React.ReactNode
  subHeading?: React.ReactNode
  thirdHeading?: React.ReactNode
  children: React.ReactNode
}

export function PageLayout(props: PageLayoutProps) {
  return (
    <div className="px-2 sm:pt-16 pb-24 flex flex-col items-center justify-center min-h-screen bg-[linear-gradient(65deg,_rgba(131,182,210,1)_0%,_rgba(146,97,165,1)_21%,_rgba(190,80,58,1)_67%,_rgba(235,128,35,1)_100%)] overflow-hidden">
      <img
        src="/lines.png"
        alt=""
        className="w-full h-full inset-0 scale absolute z-5 opacity-5"
      />

      <div className="pt-8 relative z-10 m-w-full w-[500px] px-6 mb-[40px]">
        <img
          className="w-[175px] sm:w-[287px] object-contain mx-auto"
          src="/rooquest-logo-white.png"
          alt="Rooquest"
        />
      </div>

      <div className="relative z-10 w-full max-w-[500px] py-8 sm:py-12 px-8 sm:px-16 bg-gray-50 rounded-[26px] sm:rounded-[40px] shadow-sm">
        <h1 className="text-2xl sm:text-3xl mb-[14px] text-center text-gray-800 font-medium">
          {props.heading}
        </h1>
        {props.subHeading && (
          <h2 className="text-md text-center text-gray-800 font-medium">{props.subHeading}</h2>
        )}

        {props.thirdHeading && (
          <h3 className="text-md mb-2 text-center text-gray-800 font-medium">
            {props.thirdHeading}
          </h3>
        )}

        {props.children}
      </div>
    </div>
  )
}
